
$unit:rem;
$unitMult:.5;

@import "../index";

.start {

  .banner {
    background-image: url("../../images/banner.jpg");
    background-position: center;
    background-size: cover;

  }


  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .copy {
    // margin-top: 2rem;
    font-size: 1rem;
    position: relative;
    top:0;

  }
  .buttons {
    padding: 0;
    .category-item {
      width: calc(50% - 1rem);
      margin: 0.5rem;
      height: 150px;
      padding: .4rem;
      svg {
        width: 50%;
        margin-top: -1rem;
      }
      h3 {
        font-size: 1rem;
      }
    }
  }
}
